






import { Chart } from "highcharts-vue";
import * as Highcharts from "highcharts";
import Vue from "vue";
import formatter from "@/mixins/formatter";
import { Component, Prop, Watch } from "vue-property-decorator";
import { fromExcelFormat } from "@/utils/excel-format";
import { dataAverage } from "@/utils";
import { Entity } from "@/smartmsi";


@Component({ components: { Chart }, mixins: [formatter] })
export default class InstrumentChart extends Vue {
  @Prop() xmlChart!: string;
  @Prop() instrument!: Entity;
  @Prop({ default: 5 }) nbYears!: number;

  chartOptions = {
    chart: {
      height: 200
    },
    title: {
      text: undefined,
    },
    credits: {
      text: '',
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        year: '%Y'
      },
      // tickInterval: Date.UTC(2010, 0, 1) - Date.UTC(2009, 0, 1)
    },
    yAxis: {
      labels: {},
      title: {
        text: undefined
      }
    },
    series: [],
  } as Highcharts.Options;

  created() {
    this.prepare();
  }

  get instrumentData() {
    const o: Record<string, any> = {};
    const data = Object.keys(this.instrument.datum || {}).length > 0
      ? this.instrument.datum
      : this.instrument.datumSmart; 
    if (!data || Object.keys(data).length === 0) {
      return o; // Return an empty object if no data is found
    }

    const years = Object.keys(data);
    const newYears = years.slice(-1 * this.nbYears);

    years.forEach(key => {
      if (key >= newYears[0]) {
        o[key] = data[key];
      }
    });

    return o;
  }

  prepare() {
    const years = Object.keys(this.instrumentData);
    const format = this.instrument.excel_format;
    const referent = this.instrument.ref_val;
    const data = this.instrumentData;

    const average = dataAverage(data, 1);

    const seriesData = [
      [],
      [],
      [],
    ];

    years.forEach(year => {
      seriesData[0].push(data[year].value || 0);
      seriesData[1].push(average);
      seriesData[2].push(referent);
    });

    const parser = new DOMParser();
    const doc = parser.parseFromString(this.xmlChart, "application/xml");
    const colorSchemes = {
      accent3: 'green',
      bg1: 'white',
      tx1: 'black',
    }

    // find the chart
    const cChart = doc.getElementsByTagName('c:chart')[0];
    const cPlotArea = cChart.getElementsByTagName('c:plotArea')[0];
    const cLineChart = cPlotArea.getElementsByTagName('c:lineChart')[0];
    const cSeries = cLineChart.getElementsByTagName('c:ser');

    // yAxis format
    this.chartOptions.yAxis.labels.formatter = function (this: Highcharts.AxisLabelsFormatterContextObject) {
      return fromExcelFormat('undefined' !== typeof this.value ? this.value : this.y, format);
    };

    const series: Highcharts.Series[] = [];
    const labels = [this.translateName(this.instrument), 'Moyenne', 'Référent MSI <sup><small>&reg;</small></sup>'];
    [].slice.call(cSeries).forEach((cSer: Element, cIdx) => {
      let color;
      const cCol = cSer.getElementsByTagName('a:solidFill')[0].firstElementChild;
      switch (cCol.tagName) {
        case 'a:srgbClr':
          color = '#' + cCol.getAttribute('val');
          break;
        case 'a:schemeClr':
          color = colorSchemes[cCol.getAttribute('val')];
          break;
      }

      const serie = {
        type: 'line',
        name: labels[cIdx],
        color,
        data: [],
        marker: { enabled: false },
        tooltip: {
          pointFormatter: (function (this: Highcharts.AxisLabelsFormatterContextObject) {
            return fromExcelFormat('undefined' !== typeof this.value ? this.value : this.y, format);
          }) as Highcharts.AxisLabelsFormatterCallbackFunction
        },
      };

      years.forEach((year, idx) => {
        serie.data.push([
          Date.UTC(year, 0, 1),
          parseFloat(seriesData[cIdx][idx])
        ]);
      });

      series.push(serie);
    });
    this.chartOptions.series = series;
    this.$forceUpdate();
  }

  @Watch("instrument", { deep: true })
  onValueChanged() {
    this.prepare();
  }
}
