

























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Company, Entity } from "@/smartmsi.d";
import formatter from "@/mixins/formatter";
import { fromExcelFormat } from "@/utils/excel-format";

import InstrumentChart from '@/components/charts/InstrumentChart.vue';
import ConclusionGraph from "@/components/charts/ConclusionGraph.vue";
import { dataAverage } from "@/utils";

@Component({ components: { InstrumentChart, ConclusionGraph }, mixins: [formatter] })
export default class InstrumentCard extends Vue {
  @Prop() instrument!: Entity;
  @Prop() xmlChart!: string;
  @Prop() company!: Company;
  @Prop({ default: 5 }) nbYears!: number;

  format(value: number, format: string) {
    return fromExcelFormat(value, format);
  }
  average(data: Record<number, Record<string, number>>) {
    return dataAverage(data, 1);
  }

  get instrumentData() {
    const o = {};

    // Determine the source array to use
    const source = Object.keys(this.instrument.datum || {}).length > 0
      ? this.instrument.datum
      : this.instrument.datumSmart;
    // Extract the years and slice to get the last `nbYears`
    const years = Object.keys(source || {});
    const newYears = years.slice(-1 * this.nbYears);


    // Populate the filtered object with the relevant data
    Object.keys(source || {}).forEach(key => {
      if (key >= newYears[0]) {
        o[key] = source[key];
      }
    });

    return o;
  }

  get latestYear() {
    // Determine the source array to use
    const source = Object.keys(this.instrument.datum || {}).length > 0
      ? this.instrument.datum
      : this.instrument.datumSmart;

    // Return the maximum year from the keys
    return Math.max(...Object.keys(source || {}));
  }

  get conformity() {
    const data = this.instrument.datum && Object.keys(this.instrument.datum).length
      ? this.instrument.datum
      : this.instrument.datumSmart;
    if (!data || Object.keys(data).length === 0) {
      return '???'; // Return default value if both datum and datumSmart are empty
    }

    const latestYearValue = data[this.latestYear]?.value;

    if (!latestYearValue) {
      return '???'; // Return default value if no value is found for the latest year
    }

    switch (this.instrument.ref_comp) {
      case '>':
        return parseFloat(latestYearValue) >= parseFloat(this.instrument.ref_val);
      case '<':
        return parseFloat(latestYearValue) <= parseFloat(this.instrument.ref_val);
      default:
        return '???'; // Handle unsupported comparison operators
    }
  }



}

