





























































import { Component, Prop, Vue } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import { Company } from "@/smartmsi";
import UploadError from "@/components/UploadError.vue";

@Component({
  components: { UploadError },
  mixins: [formatter],
})
export default class UploadCloseData extends Vue {
  @Prop() company!: Company;

  dataFile: string | Blob | null = null;
  uploadDataPercentage = 0;
  uploadingData = false;
  // consent = false;
  error = null;
  data = [];
  created() {
    this.getData();
  }
  uploadData() {
    this.uploadingData = true;
    const formData = new FormData();
    formData.append("file", this.dataFile);
    this.error = null;
    this.$api
      .post("company/import-closing-data?id=" + this.company.id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: progressEvent => {
          this.uploadDataPercentage = parseInt(
            Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            ).toString()
          );
        },
      })
      .then(() => {
        console.log("SUCCESS!!");
        this.$emit("done");
        this.getData()
      })
      .catch(e => {
        this.error = e.response.data;
      })
      .finally(() => {
        this.uploadingData = false;
      });
  }
  getData() {

    this.$api.get(`company/get-closing?id=${this.company.id}`).then(res => {
      this.data = res.data;
      console.log("countrys",res.data)
    });
  }
}
