








import { Chart } from "highcharts-vue";
import * as Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HC_3d from "highcharts/highcharts-3d";
import Vue from "vue";
import ChartsHelper from "@/utils/charts";

HC_more(Highcharts);
HC_3d(Highcharts);

export default Vue.extend({
  name: "Single",
  components: { Chart },
  props: ["companyId", "graphId", "graph"],
  data() {
    return {
      chartOptions: {
        chart: {
          zoomType: 'xy'
        },
        tooltip: {
          shared: true,
        },
        title: {
          text: "Loading ..",
        },
        credits: {
          text: "SmartMSI",
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            year: "%Y",
          },
          // tickInterval: Date.UTC(2010, 0, 1) - Date.UTC(2009, 0, 1)
        },
        yAxis: {
          min: 0,
        },
        series: [],
      } as Highcharts.Options,
    };
  },

  created() {

    const TYPE_SINGLE = "1";
    const TYPE_DOUBLE = "2";
    // const TYPE_MULTI = '3';
    const TYPE_FORECAST = '6';

    const conclusionGraph = this.graph;
    const conclusionGraphSeries = this.graph.conclusionGraphSeries;

    let years;
    // years
    if (conclusionGraph.type.toString() === TYPE_SINGLE) {
      years = [2020];
    } else if (conclusionGraph.type.toString() === TYPE_DOUBLE) {
      years = [2019, 2020];
    } else {
      years = false;
    }

    const types = {
      barChart: "column",
      lineChart: "line",
    };

    // prepare options
    const options = {
      title: { text: conclusionGraph.name },
      yAxis: {
        title: { text: null },
      },
    } as Highcharts.Options;

    const valuesAxesCount = this.graph.xml.split('<c:valAx>').length - 1;

    if (valuesAxesCount === 2) {
      options.yAxis = [
        { 'title': { text: null } },
        { 'title': { text: null }, opposite: true }
      ];
    }

    // prepare series
    let series = [];

    if (years) {
      // pie or radar

      const type =
        'pie3DChart' === conclusionGraphSeries[0]['type'] ? 'pie' : 'radar';

      if ('pie' === type) {
        options.chart = {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 65,
            beta: 0,
          },
        };
        options.plotOptions = {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 55,
            slicedOffset: 50,
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b> ({point.percentage:.2f}%)',
            },
          },
        };
      } else {
        options.chart = {
          polar: true,
          type: 'line',
        };
        options.pane = {
          startAngle: -36,
        };
        options.xAxis = {
          categories: [],
          tickmarkPlacement: 'on',
          lineWidth: 0,
        };

        options.yAxis = {
          gridLineInterpolation: 'polygon',
          lineWidth: 0,
          min: 0,
          max: 10,
          tickColor: '#ffffff',
          allowDecimals: false,
          tickInterval: 1,
          tickAmount: 6,
          tickLength: 10,
          gridLineColor: '',
          tickmarkPlacement: 'between',
          tickPixelInterval: 100,
          tickPosition: 'outside',
          title: { text: null },
        };
      }

      years.forEach((year, $k) => {
        const serie = {
          name: year,
          data: [],
        } as Highcharts.SeriesLineOptions | Highcharts.SeriesAreaOptions;

        // if two years, first one dashed
        if (2 === years.length) {
          if (0 === $k) {
            serie.type = 'line';
            serie.dashStyle = 'ShortDash';
            serie.lineWidth = 1;
            serie.zIndex = 2;
          } else {
            serie.type = 'area';
          }
        }
        series.push(serie);
      });

      conclusionGraphSeries.forEach(($cgs) => {
        let k = 0;
        years.forEach((year) => {
          const $cgd = ($cgs.conclusionGraphDatum || $cgs.conclusionGraphDatumSmart || {})[year];
          const data = {
            name: $cgs.name,
            y: parseFloat($cgd.value),
          } as Highcharts.Point;

          if (10 === this.graphId) {
            data.sliced = true;
          }
          if (2 === this.graphId || 44 === this.graphId) {
            options.xAxis.categories.push($cgs.name);
          }

          series[k].data.push(data);
          k++;
        });
      });
    } else {
      // lines && bars

      if (conclusionGraph.type === TYPE_FORECAST) {
        series = ChartsHelper.prepareForecastSeries(conclusionGraphSeries);
      } else {

        // add series
        conclusionGraphSeries.forEach($cgs => {
          const serie = {
            name: $cgs.name,
            type: types[$cgs.type],
            yAxis: valuesAxesCount === 2 && $cgs.type === "barChart" ? 1 : 0, // 2 yAxis ?
            zIndex: valuesAxesCount === 2 && $cgs.type === "barChart" ? 0 : 1,
            data: [],
            marker: { enabled: false },
          } as Highcharts.SeriesOptionsType & { marker: Highcharts.PointMarkerOptionsObject };

          if ("auto" !== $cgs.color) {
            serie.color = "#" + $cgs.color;
            serie.marker.lineColor = "#" + $cgs.color;
          }

          // add data
          Object.values($cgs.conclusionGraphDatum || $cgs.conclusionGraphDatumSmart || {}).forEach(($cgd) => {
            serie.data.push([ChartsHelper.yearToDate($cgd.year), parseFloat($cgd.value)]);
          });
          series.push(serie);
        });


      }
    }

    // bar between 2 series
    if (this.graph.xml.indexOf('upDownBars') > -1) {
      const serie = {
        type: "columnrange",
        showInLegend: false,
        data: [],
      };

      this.chartOptions.tooltip.formatter = ChartsHelper.upDownBarsTooltipFormatter;

      series[0]["data"].forEach(($void, $k) => {
        serie["data"].push([
          series[0]["data"][$k][0],
          series[0]["data"][$k][1],
          series[1]["data"][$k][1],
        ]);
      });
      series = [serie, ...series];
    }

    options.series = series;

    /************* END ADD ON */

    this.chartOptions = { ...this.chartOptions, ...options };

  },

});
