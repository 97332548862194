export const dataAverage = (
  data: Record<string, { value: string }>,
  minus = 0
) => {
  if (!data || Object.keys(data).length === 0) {
    return 0; // Return 0 or another default value if the data is empty
  }

  let total = 0;
  // Extract the values and account for the minus parameter
  const values = Object.values(data).slice(
    0,
    Object.values(data).length - minus
  );

  values.forEach(datum => {
    total += parseFloat(datum.value) || 0; // Ensure value is a valid number, defaulting to 0 if not
  });

  return values.length > 0 ? total / values.length : 0; // Avoid division by zero
};
