import { GlobalState } from "@/smartmsi";

const storedUser = localStorage.getItem("user") || false;
const user = storedUser ? JSON.parse(storedUser) : null;

const storedImpersonater = localStorage.getItem("impersonator") || false;
const impersonator = storedImpersonater ? JSON.parse(storedImpersonater) : null;

export default {
  authStatus: "",
  token: localStorage.getItem("access_token") || "",
  user,
  impersonator: impersonator,
  tableList: [],
  errors: [],
  snack: {
    show: false,
    text: '',
    html: '',
    color: '',
  },
  availableLanguages: [],
  activities: [],
  rules: [],
  data: {},
  configUploads: [],
  versionUploads: [],
  closeUploads: []
} as GlobalState;
