import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import i18n from "./i18n";
import store from "@/store";
import { TranslateResult } from "vue-i18n";

interface Attribute {
  name: string;
  label?: string | TranslateResult;
  value?: string;
  width?: number;
  choices?: string;
  if?: string;
  noImport?: boolean;
  bool?: boolean;
}

interface Col {
  name: string;
  label: string;
}

type Attr = Attribute | string;

interface ModelsConfig {
  [propName: string]: (Attribute | string)[];
}

interface Item {
  [propName: string]: string;
}

const Models: ModelsConfig = {
  axe: ["id", "code", "name", "description"],
  company: [
    { name: "id", width: 5, noImport: true },
    { name: "name", width: 30 },

    { name: "companyActivities.0.activity.name.fr", label: "Activity 1" },
    { name: "companyActivities.0.percent", label: "Percent 1" },
    { name: "companyActivities.1.activity.name.fr", label: "Activity 2" },
    { name: "companyActivities.1.percent", label: "Percent 2" },

    "phone",
    "email",
    { name: "address", width: 10 },
    { name: "city", width: 10 },
    { name: "state", width: 10 },
    { name: "zipcode", width: 10 },
    "symbol",
    "isin",
    "score",
    { name: "performance_score", label: "performance" },
    { name: "solidity_score", label: "solidity" },
    {
      name: "country_id",
      value: "country.code",
      choices: "country",
      width: 8,
      label: i18n.t("models.company.country"),
    },

    { name: "contacts.0.name" },
    { name: "contacts.0.gender" },
    { name: "contacts.0.title" },
    { name: "contacts.0.phone" },
    { name: "contacts.0.email" },

    "tax_registration_number",
    "national_id",
    "international_id",
    {
      name: "legal_typology_id",
      choices: "legalTypology",
      value: "legalTypology.code",
      label: i18n.t("models.company.legalTypology"),
    },
    { name: "traded", width: 7, bool: true },
    {
      name: "stock_market_id",
      value: "stockMarket.code",
      choices: "stockMarket",
    },
    { name: "traded_index", if: "traded" },
    { name: "traded_code", if: "traded" },
    { name: "traded_date", if: "traded" },

    "image",
    "url",
    { name: "web", width: 7 },
    { name: "enabled", width: 7 },
    { name: "created_at", noImport: true },
    { name: "updated_at ", noImport: true },
  ],
  StockVersion: ["company", "company_id"],
};

Object.keys(Models).forEach(k => {
  const cols = Models[k];
  cols.forEach((col, idx) => {
    if ("string" === typeof col) cols[idx] = { name: col, label: col };
  });
});

const makeObj = (x: Attr) => {
  if ("string" === typeof x) x = { name: x };
  return { value: x.name, ...x };
};

const get = (obj: object, path: string, def?: any) => {
  if (!obj) return def || null;
  const attributes: string[] = path.split(".");
  const translate = path.match(/\bname$/);
  let o = obj;
  for (let i = 0; i < attributes.length; i++) {
    if (undefined === o || null === o) return undefined === def ? null : def;
    // @ts-ignore
    o = o[attributes[i]];
  }
  if (undefined === o || null === o) return undefined === def ? null : def;

  // @ts-ignore
  if (translate && (o.fr || o.en)) {
    // @ts-ignore
    return o[i18n.locale] ?? o.en ?? o[Object.keys(o)[0]];
  }

  return o;
};

export const Load = (model: string, buffer: ArrayBuffer) => {
  const wb = new ExcelJS.Workbook();

  return wb.xlsx.load(buffer).then(workbook => {
    let cols = Models[model];
    const items: Item[] = [];

    workbook.eachSheet(sheet => {
      sheet.eachRow((row, i) => {
        // first row is headers
        if (i === 1) {
          row.eachCell(cell => {
            if (!cell.name) {
              console.error("Invalid XLS with missing names");
            }
            // @ts-ignore
            Models[model].find(c => c.name === cell.name);
          });
        } else {
          // prepare item
          const item = {};
          // @ts-ignore
          cols.forEach(col => (item[col.name] = {}));
          // read values (skip 1st column id)
          // @ts-ignore
          const values = row.values.splice(1);
          // @ts-ignore
          values.forEach((val, x) => {
            // @ts-ignore
            if (cols[x].choices) {
              // @ts-ignore
              val = store.getters.code2id(cols[x].choices, val);
            }

            // @ts-ignore
            item[cols[x].name] = {
              value: "string" === typeof val ? val.trim() : val,
              validation: {},
            };
          });
          items.push(item);
        }
      });
    });

    // remove noImports
    cols.forEach(col => {
      // @ts-ignore
      if (col.noImport) {
        // @ts-ignore
        items.forEach(item => delete item[col.name]);
      }
    });
    // @ts-ignore
    cols = cols.filter(col => !col.noImport);

    return { cols, items };
  });
};

export const Export = (model: string, items: object[], filename: string) => {
  const attributes = Models[model].map(makeObj);

  const workbook = new ExcelJS.Workbook();
  workbook.creator = "Smart MSI";
  workbook.created = new Date();

  const worksheet = workbook.addWorksheet("Items", {
    views: [{ state: "frozen", xSplit: 0, ySplit: 1 }],
  });

  // identify columns
  worksheet.columns = attributes.map(f => {
    return {
      header: f.label,
      key: f.name,
      width: f.width || 15,
    };
  });

  // header
  const header = worksheet.getRow(1);
  header.height = 30;
  header.eachCell(cell => {
    cell.font = { name: "Calibri", size: 10, bold: true };
    cell.alignment = { vertical: "middle", horizontal: "center" };
    // cell.fill = { type: 'pattern', pattern:'solid', fgColor: { argb:'00EEEEEE' } };
  });
  attributes.forEach(f => {
    // @ts-ignore
    header.getCell(f.name).value =
      f.label ?? i18n.t("models." + model + "." + f.name);
    header.getCell(f.name).name = f.name;
  });

  // the data
  for (let i = 0; i < items.length; i++) {
    const row = worksheet.getRow(i + 2);
    attributes.forEach(f => {
      const cell = row.getCell(f.name);
      cell.value = get(items[i], f.value);
      cell.font = { name: "Calibri", size: 10 };
    });
  }

  // trigger download
  workbook.xlsx.writeBuffer().then(function(data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, filename);
  });
};
export const ExportByEndpoint = (
  model: string,
  items: object[],
  filename: string
) => {
  const attributes = Models[model].map(makeObj);

  console.log(Models[model]);
  const workbook = new ExcelJS.Workbook();
  workbook.creator = "Smart MSI";
  workbook.created = new Date();

  const worksheet = workbook.addWorksheet("Items", {
    views: [{ state: "frozen", xSplit: 0, ySplit: 1 }],
  });

  // identify columns
  worksheet.columns = attributes.map(f => {
    return {
      header: f.label,
      key: f.name,
      width: f.width || 15,
    };
  });

  // header
  const header = worksheet.getRow(1);
  header.height = 30;
  header.eachCell(cell => {
    cell.font = { name: "Calibri", size: 10, bold: true };
    cell.alignment = { vertical: "middle", horizontal: "center" };
    // cell.fill = { type: 'pattern', pattern:'solid', fgColor: { argb:'00EEEEEE' } };
  });
  attributes.forEach(f => {
    // @ts-ignore
    header.getCell(f.name).value =
      f.label ?? i18n.t("models." + model + "." + f.name);
    header.getCell(f.name).name = f.name;
  });

  // the data
  for (let i = 0; i < items.length; i++) {
    const row = worksheet.getRow(i + 2);
    attributes.forEach(f => {
      const cell = row.getCell(f.name);
      cell.value = get(items[i], f.value);
      cell.font = { name: "Calibri", size: 10 };
    });
  }

  // trigger download
  workbook.xlsx.writeBuffer().then(function(data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, filename);
  });
};
export const ExportStockVersions = (model, items, filename) => {
  // Sort items by date_version in ascending order
  items.sort((a, b) => new Date(a.date_version) - new Date(b.date_version));

  const workbook = new ExcelJS.Workbook();
  workbook.creator = "Smart MSI";
  workbook.created = new Date();

  items.forEach(item => {
    // Format date_version as "yyyy-mm-dd" without time zone conversion
    const date = new Date(item.date_version);
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

    // Create a worksheet with formattedDate as the sheet name
    const worksheet = workbook.addWorksheet(formattedDate, {
      views: [{ state: "frozen", xSplit: 0, ySplit: 1 }],
    });

    // Set up the header row for the sheet
    worksheet.columns = [
      { header: "Company", key: "company", width: 30 },
      { header: "Company ID", key: "company_id", width: 15 },
    ];

    // Style the header row
    const header = worksheet.getRow(1);
    header.height = 20;
    header.eachCell(cell => {
      cell.font = { name: "Calibri", size: 12, bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Populate rows with stockExchangeComposition data using addRow to avoid extra empty rows
    item.stockExchangeComposition.forEach(composition => {
      worksheet.addRow({
        company: composition.company.name,
        company_id: composition.company.id,
      });
    });
  });

  // Trigger download
  workbook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, filename);
  });
};
export default Export;
