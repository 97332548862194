

























































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {ApiCompanyDataResponse, Company} from "@/smartmsi.d";
import formatter from "@/mixins/formatter";
import {fromExcelFormat} from "@/utils/excel-format";

import InstrumentCard from '@/components/instrument/Card.vue';
import ConclusionGraph from "@/components/charts/ConclusionGraph.vue";
import {dataAverage} from "@/utils";

@Component({components: {InstrumentCard, ConclusionGraph}, mixins: [formatter]})
export default class ViewAnalysis extends Vue {
  @Prop() company!: Company;
  @Prop() data!: ApiCompanyDataResponse;
  @Prop() version!: number;

  loading = true;
  updateKey = 0;

  created() {
    this.updateKey = this.version;
  }

  mounted() {
    this.updateKey = this.version;
  }

  format(value: string, format: string) {
    return fromExcelFormat(value, format);
  }

  average(data) {
    return dataAverage(data);
  }

  get cgs() {
    const cgs = [];
    if (this.data.conclusionsGraphs) {
      this.data.conclusionsGraphs.forEach(cg => {
        if (!cgs[cg.entity_id]) {
          cgs[cg.entity_id] = [];
        }
        cgs[cg.entity_id].push(cg);
      });
    }

    return cgs;
  }
}

